<template>
    <div class="k-card-1">
        <h1>Builds dans les dernières 24h</h1>
        <div
            class="k-flex-row k-flex-fill"
            v-b-tooltip.bottom.v-light
            :title="`Le ${formattedCreated}`"
        >
            <div class="k-flex-center">
                <span class="k-text-25px text-kalkin-2 mr-1">{{ done }}</span>
                <small>réussite{{ done > 1 ? "s" : "" }}</small>
            </div>
            <div class="k-flex-center">
                <span class="k-text-25px text-warning mr-1">{{ partial }}</span>
                <small>incomplet{{ partial > 1 ? "s" : "" }}</small>
            </div>
            <div class="k-flex-center">
                <span class="k-text-25px text-danger mr-1">{{ failed }}</span>
                <small>échec{{ failed > 1 ? "s" : "" }}</small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            done: 0,
            partial: 0,
            failed: 0,
            created: null,
        };
    },

    computed: {
        formattedCreated() {
            return this.$options.filters.dateFromISO(this.created);
        },
    },

    async beforeMount() {
        const raw = await this.$store.dispatch("server/getBuildsStats");

        if (raw) {
            this.done = raw.done || 0;
            this.partial = raw.partial || 0;
            this.failed = raw.failed || 0;
            this.created = raw.created;
        }
    },
};
</script>
