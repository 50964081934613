<template>
    <div class="k-card-1">
        <h1>Installations connectées dans les dernières 24h</h1>
        <div
            class="k-flex-row k-flex-fill"
            v-b-tooltip.bottom.v-light
            :title="`Le ${formattedCreated}`"
        >
            <div class="k-flex-center">
                <span class="k-text-25px text-kalkin-2 mr-1">{{
                    tables_on
                }}</span>
                <span class="text-kalkin-1 text-nowrap mr-1">
                    / {{ tables }}
                </span>
                <small>table{{ tables > 1 ? "s" : "" }}</small>
            </div>
            <div class="k-flex-center">
                <span class="k-text-25px text-kalkin-2 mr-1">{{
                    bornes_on
                }}</span>
                <span class="text-kalkin-1 text-nowrap mr-1">
                    / {{ bornes }}
                </span>
                <small>borne{{ bornes > 1 ? "s" : "" }}</small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            tables: 0,
            tables_on: 0,
            bornes: 0,
            bornes_on: 0,
            created: null,
        };
    },

    computed: {
        formattedCreated() {
            return this.$options.filters.dateFromISO(this.created);
        },
    },

    async beforeMount() {
        const raw = await this.$store.dispatch("server/getInstallationsStats");
        if (raw) {
            if (raw.Table) {
                this.tables = raw.Table.total || 0;
                this.tables_on = raw.Table.contact || 0;
            }
            if (raw.Borne) {
                this.bornes = raw.Borne.total || 0;
                this.bornes_on = raw.Borne.contact || 0;
            }
            this.created = raw.created;
        }
    },
};
</script>
