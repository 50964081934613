<template>
    <div class="k-page-flex">
        <div class="k-flex-row k-flex-fill">
            <PanelBuildStats />
            <PanelInstallationStats />
            <PanelUserStats />
        </div>

        <PanelAdvancedSearch />

        <div class="k-flex-row-2">
            <h4>Activité récente</h4>
            <sup class="text-danger">Bêta</sup>
        </div>

        <ListActivity />
    </div>
</template>
<script>
import PanelUserStats from "@/components/misc/admin-home/PanelUserStats";
import PanelInstallationStats from "@/components/misc/admin-home/PanelInstallationStats";
import PanelBuildStats from "@/components/misc/admin-home/PanelBuildStats";
import PanelAdvancedSearch from "@/components/misc/admin-home/PanelAdvancedSearch";

import ListActivity from "@/components/model/activity/ListActivity";

export default {
    components: {
        PanelUserStats,
        PanelInstallationStats,
        PanelBuildStats,
        PanelAdvancedSearch,
        ListActivity,
    },
};
</script>
