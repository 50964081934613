<template>
    <div class="k-card-3 bg-light">
        <div class="text-right text-secondary">
            <small> Le {{ activity.time | dateFromISO }} </small>
        </div>

        <div class="header k-flex-row flex-nowrap flex-grow-1">
            <div>
                <b-icon
                    class="h3 m-2"
                    :icon="icon_shape"
                    :variant="icon_variant"
                />
            </div>
            <div class="flex-grow-1">
                <span class="text-kalkin-3">{{ user }}</span> {{ body }}
            </div>
        </div>

        <div class="k-flex-row flex-nowrap">
            <div class="flex-grow-1">
                <div>
                    <b-badge :style="`background:${color}`">
                        {{ targetType }}
                    </b-badge>
                </div>
                <div class="ml-1 mb-1">
                    <small> {{ targetName }}</small>
                </div>
            </div>
            <div>
                <div v-if="route">
                    <b-button
                        variant="outline-kalkin-1"
                        size="xs"
                        @click="goTo(route)"
                    >
                        <b-icon-box-arrow-right />
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { parseData } from "@/model/activity";

export default {
    props: {
        activity: { type: Object, required: true },
    },

    data: function() {
        return {
            ...parseData(this.activity),
        };
    },

    computed: {
        user() {
            try {
                const user = this.activity.user;
                return `${user.firstName} ${user.lastName.toUpperCase()}`;
            } catch (error) {
                return "Le système";
            }
        },
    },

    methods: {
        goTo(route) {
            this.$router.push(route);
        },
    },
};
</script>

<style lang="scss" scoped>
.k-card-3 .header {
    border-bottom: 2px solid #eee;
}
</style>
