<template>
    <div class="k-card-1">
        <h1>Utilisateurs connectés dans les dernières 24h</h1>
        <div
            class="k-flex-row k-flex-fill"
            v-b-tooltip.bottom.v-light
            :title="`Le ${formattedCreated}`"
        >
            <div class="k-flex-center">
                <span class="k-text-25px text-kalkin-2 mr-1">{{ member }}</span>
                <small>membre{{ member > 1 ? "s" : "" }}</small>
            </div>
            <div class="k-flex-center">
                <span class="k-text-25px text-kalkin-2 mr-1">{{ kalkin }}</span>
                <small>Kalkin</small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            member: 0,
            kalkin: 0,
            created: null,
        };
    },

    computed: {
        formattedCreated() {
            return this.$options.filters.dateFromISO(this.created);
        },
    },

    async beforeMount() {
        const raw = await this.$store.dispatch("server/getUsersStats");

        if (raw) {
            this.member = raw.member || 0;
            this.kalkin = raw.kalkin || 0;
            this.created = raw.created;
        }
    },
};
</script>
