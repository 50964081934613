<template>
    <GridCardBasic
        :data="activity"
        empty="Aucune activité récente"
        minCardSize="250px"
    >
        <template v-slot="{ data }">
            <CardActivity :activity="data" />
        </template>
    </GridCardBasic>
</template>

<script>
import GridCardBasic from "@/components/ui/GridCardBasic";
import CardActivity from "@/components/model/activity/CardActivity";

export default {
    components: {
        GridCardBasic,
        CardActivity,
    },

    data: function() {
        return {
            activity: [],
        };
    },

    methods: {
        async fetchActivity() {
            this.activity = await this.$store.dispatch("admin/getActivity");
        },
    },

    async beforeMount() {
        await this.fetchActivity();
    },
};
</script>

<style></style>
