const COLOR_DEFAULT = "#00817b";
const TYPE_DEFAULT = ["divers", "divers", "le", COLOR_DEFAULT];
const TYPES = {
    arbo: ["arborescence", "arborescences", "l'"],
    menu: ["menu", "menus", "le "],
    project: ["projet", "projets", "le ", "#b33851"],
    applicationconfig: ["configuration", "configurations", "la "],
    arbosnapshot: ["instantané", "instantanés", "l'"],
    menustyle: ["style de menu", "styles de menus", "le "],
    datasource: ["source de données", "sources de données", "la ", "#38b39a"],
    dataselection: ["sélection de données", "sélections de données", "la "],
    user: ["utilisateur", "utilisateurs", "l'", "#51b338"],
    installation: ["installation", "installations", "l'", "#386fb3"],
    contact: ["contact", "contact", "le'", "#B3AB38"],
};

const SENTENCES_DEFAULT = "a réalisé une opération inconnue %COUNT% fois";
const SENTENCES = {
    create: "a créé %COUNT% %TYPE%",
    "create-self": "a créé %ART%%TYPE%",
    update: "a modifié %COUNT% fois les %TYPE_P%",
    "update-self": "a modifié %COUNT% fois %ART%%TYPE_S%",
    delete: "a supprimé %COUNT% %TYPE%",
    "delete-self": "a supprimé %ART%%TYPE%",
};

const ICON_DEFAULT = ["question-circle", "primary"];
const ICONS = {
    create: ["plus-circle-fill", "kalkin-2"],
    update: ["gear-wide-connected", "warning"],
    delete: ["trash-fill", "danger"],
};

const ROUTES = {
    arbo: { name: "AdminEditArboStructure", param: "arboId" },
    project: { name: "AdminProject" },
    datasource: { name: "AdminDataSource", param: "datasourceId" },
    installation: { name: "AdminProjectListInstallations" },
    user: { name: "AdminUser", param: "userId", noProject: true },
};

module.exports = {
    parseData(activity) {
        const [type, act, opt] = activity.action.split(".");

        let route = null;
        let routeInfos = ROUTES[activity.context.type];
        if (routeInfos) {
            if (
                (activity.context.projectId || routeInfos.noProject) &&
                !(act === "delete" && opt === "self")
            ) {
                route = {
                    name: routeInfos.name,
                    params: {
                        projectId: activity.context.projectId,
                        [routeInfos.param]: activity.context.id,
                    },
                };
            }
        }
        const target_type = TYPES[activity.context.type] || TYPE_DEFAULT;
        return {
            targetType: target_type[0],
            targetName: activity.context.name,

            icon_shape: (ICONS[act] || ICON_DEFAULT)[0],
            icon_variant: (ICONS[act] || ICON_DEFAULT)[1],
            color: target_type[3] || COLOR_DEFAULT,

            body: _createBody(activity, type, act, opt),
            route,
        };
    },

    createBadgeInfo(type) {
        const target_type = TYPES[type] || TYPE_DEFAULT;
        return {
            src: type,
            color: target_type[3] || COLOR_DEFAULT,
            label: target_type[0],
        };
    },
};

function _createBody(activity, type, action, option) {
    let usePlural = activity.count > 1;
    if (option === "self") {
        action += "-self";
        usePlural = false;
    }
    return (SENTENCES[action] || SENTENCES_DEFAULT)
        .replace("%COUNT%", activity.count)
        .replace("%TYPE%", (TYPES[type] || TYPE_DEFAULT)[usePlural ? 1 : 0])
        .replace("%TYPE_S%", (TYPES[type] || TYPE_DEFAULT)[0])
        .replace("%TYPE_P%", (TYPES[type] || TYPE_DEFAULT)[1])
        .replace("%ART%", (TYPES[type] || TYPE_DEFAULT)[2]);
}
