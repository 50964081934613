<template>
    <div class="k-flex-col m-1">
        <div class="k-page-title">
            <h4 class="m-0">Recherche rapide</h4>
        </div>
        <div class="flex-grow-1 pt-4 pb-4">
            <b-input-group>
                <template #prepend>
                    <b-input-group-text>
                        <b-icon-search />
                    </b-input-group-text>
                </template>
                <b-form-input
                    placeholder="Rechercher un projet, un utilisateur, une installation, ..."
                    v-model="filter"
                    debounce="500"
                />
            </b-input-group>
        </div>
        <b-collapse :visible="isCollapsed">
            <b-table
                class="mt-2"
                :items="dataProvider"
                :fields="fields"
                :filter="filter"
                collapse
                empty-text="Aucune sélection"
                empty-filtered-text="La recherche n'a pas aboutie"
                stacked="md"
                hover
                thead-class="d-none"
                tbody-tr-class="pointer"
                striped
                show-empty
                @row-clicked="goToPage"
            >
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Chargement des données...</strong>
                    </div>
                </template>
                <template #cell(name)="data">
                    <b-badge :style="`background:${data.item.type.color}`">
                        {{ data.item.type.label }}
                    </b-badge>
                    {{ data.item.name }}
                </template>
                <template #cell(description)="data">
                    {{ data.item.description }}
                </template>
                <template #cell(createdAt)="data">
                    {{ data.item.createdAt | dateFromISO(true) }}
                </template>
            </b-table>
        </b-collapse>
    </div>
</template>

<script>
import { createBadgeInfo } from "@/model/activity";

export default {
    components: {},

    data: function() {
        return {
            totalRows: 0,
            isCollapsed: false,
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    key: "name",
                    label: "Nom",
                    sortable: true,
                    tdClass: "text-kalkin-1 font-weight-bold",
                },
                {
                    key: "description",
                    label: "Description",
                },
                {
                    key: "createdAt",
                    label: "Création",
                    sortable: true,
                    tdClass: "text-nowrap text-center px-4",
                },
            ],
            filter: "",
        };
    },
    methods: {
        async dataProvider(ctx) {
            if (ctx.filter !== "") {
                this.isCollapsed = true;
                var ret = await this.$store.dispatch(
                    "admin/advancedSearchGetAll",
                    {
                        search: ctx.filter,
                    },
                );
                return [
                    ...ret.users.map(user => {
                        return {
                            ...user,
                            name: user.firstName + " " + user.lastName,
                            description: user.mail,
                            type: createBadgeInfo("user"),
                        };
                    }),
                    ...ret.installations.map(install => {
                        return {
                            ...install,
                            description:
                                install.type +
                                " - " +
                                (install.place || "Non-défini"),
                            type: createBadgeInfo("installation"),
                        };
                    }),
                    ...ret.contacts.map(contact => {
                        return {
                            ...contact,
                            description: contact.mail || "Non-défini",
                            type: createBadgeInfo("contact"),
                        };
                    }),
                    ...ret.projects.map(project => {
                        return {
                            ...project,
                            type: createBadgeInfo("project"),
                        };
                    }),
                ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            } else {
                this.isCollapsed = false;
            }
        },

        goToPage(data) {
            var label = data.type.src;
            if (label === "project") {
                this.$router.push({
                    name: "AdminProject",
                    params: {
                        projectId: data.id,
                    },
                });
            } else if (label === "user") {
                this.$router.push({
                    name: "AdminUser",
                    params: {
                        userId: data.id,
                    },
                });
            } else if (label == "contact") {
                this.$router.push({
                    name: "AdminContact",
                    params: { contactId: data.id },
                });
            } else if (label == "installation") {
                this.$router.push({
                    name: "AdminProjectListInstallations",
                    params: { projectId: data.projectId },
                });
            }
        },
    },
};
</script>

<style></style>
